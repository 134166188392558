var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    {
      tag: "component",
      staticClass: "badge",
      class: [
        "badge-" + _vm.type,
        _vm.rounded ? "badge-pill" : "",
        _vm.circle && "badge-circle"
      ]
    },
    [_vm._t("default", [_vm.icon ? _c("i", { class: _vm.icon }) : _vm._e()])],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }